@font-face {
  font-family: JosefinSans-Bold;
  src: url("./assets/fonts/JosefinSans-Bold.ttf");
}

@font-face {
  font-family: JosefinSans-Medium;
  src: url("./assets/fonts/JosefinSans-Medium.ttf");
}

@font-face {
  font-family: JosefinSans-Light;
  src: url("./assets/fonts/JosefinSans-Light.ttf");
}

@font-face {
  font-family: JosefinSans-ExtraLight;
  src: url("./assets/fonts/JosefinSans-ExtraLight.ttf");
}

body {
  margin: 0;
  font-family: JosefinSans-Medium !important;
}

p {
  text-align: left;
}

h1,
h3 {
  text-transform: uppercase;
}

.img-saturate {
  filter: saturate(0.5);
}
.content {
  margin-top: 123px;
}
.jacollabs-grid {
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}
.jasmallcard {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.jasmallcard > .col {
  display: flex;
  justify-content: center;
}

.jasmallcard > .col > a {
  text-align: center;
}

.jafooter {
  background-color: #f3f3f3 !important;
}

.jafooter > div > h3 {
  font-size: 20px;
  font-weight: bold;
}
.jafooter > div > div > a {
  color: #7ab730 !important;
}

.jafooter > div > div > span {
  color: #656565 !important;
}
.jafooter > div > div > a,
.jafooter > div > div > span {
  font-size: 17px !important;
}
.jacopyright {
  background-color: #000000;
  color: #ffffff;
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

#copyright {
  font-size: 20px;
}

.paws-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 400px !important;
  margin-bottom: 50px;
}
.javideos {
  display: flex !important;
  justify-content: center !important;
  gap: 20px !important;
}

.javideos > div {
  width: 200px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;

  background-color: #f3f3f3 !important;
}

.jaabout-img {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 10px;
  width: auto;
}
