.jacard {
  border: none !important;
}
.jacard-header {
  font-family: JosefinSans-Medium !important;
  background: none !important;
  border: none !important;
  margin-top: 50px;
}

.jacard-p {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5;
  color: #656565;
}

.jasmallcard {
  background-color: #f3f3f3 !important;
  border: none !important;
  border-radius: 0 !important;
}

.jasmallcard > .col > a {
  font-size: 20px;
  font-weight: bold;
  color: #7ab730 !important;
}
