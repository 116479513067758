.jabanner {
  display: flex;
  background-image: url("../images/Carousel/JACarousel-image1.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 800px;
  margin-top: 72px !important;
}

.title {
  display: flex;
  align-items: center;
  padding-left: 1em;
  margin-right: 30px;
  grid-column: 1 / -1;
  grid-row: 1;

  font-family: JosefinSans-Medium !important;
  font-size: 5vw;
  width: 100%;

  > .title-inner {
    display: inline-block;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
  }
}

@keyframes text-clip {
  from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}

@keyframes inner-left {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: none;
  }
}

.jatitle,
.jasubtitle {
  margin-left: 25px;
}

.title-inner {
  display: inline-block;
  //animation: inner-left 1s 1s ease both;
}

.jatitle {
  .jatitle-inner {
    font-size: 80px;
    font-weight: bold;
    display: inline-block;
    color: #ffffff;
  }
}

.jasubtitle-inner {
  font-size: 40px;
  animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.title {
  animation: outer-left 1s 1s ease both;
}

.jasubtitle {
  display: inline-block;
  color: #ffffff;
}

.image {
  filter: saturate(0.5);
  /*  grid-row: 1;
  grid-column: 2; */
  margin-left: -2rem;
  animation: image-in 1s linear;
  @keyframes image-in {
    from {
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
