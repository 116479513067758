.jabutton {
  background-color: transparent !important;
  color: #7ab730 !important;

  border: none !important;
  border-radius: 0 !important;
}

.jabutton.active {
  font-size: 15px;
  background-color: #7ab730 !important;
  color: #ffffff !important;
}
