@media (min-width: 280px) and (max-width: 813px) {
  body {
    width: 100vw;
  }
  .janavbar {
    flex-direction: column;
    top: 0;
    padding-bottom: 5px;
    width: 100% !important;
    .nav-item {
      align-self: center;
    }
  }
  .janavbar > .nav-item > img {
    width: 300px;
  }

  .janavbar-info {
    display: flex;
    align-self: center;
    //justify-content: flex-start !important;
    div > a > span {
      display: none;
    }
  }
  .janavbar-info > .nav-item > a {
    font-size: 14px;
    img {
      width: 20px !important;
    }
  }
  .jaflag {
    width: 20px;
    height: 20px;
  }

  .janavbar > .nav-item > .jaflags  {
    position: absolute;
  }
  .jasocials > a > img {
    width: 20px;
  }
  .content {
    margin-top: 25px;
  }
  h1 {
    font-size: 18px !important;
    text-align: center;
  }

  .jabutton {
    font-size: 13px !important;
  }
  .jatitle-inner {
    font-size: 30px !important;
    text-align: center;
  }
  .jasubtitle-inner {
    font-size: 19px !important;
    text-align: center;
  }
  .jabanner {
    width: 100% !important;
    margin-top: 78px !important;
    height: 200px !important;
  }

  .img-paw {
    width: 40px;
    height: 40px;
  }

  .card-body {
    width: 100%;
    text-align: -webkit-center;

    .container > .row {
      display: flex;
      flex-direction: column;

      .col:last-child {
        margin-bottom: 20px;
      }
    }
  }
  .card-donate-body {
    width: 50%;
  }

  .img-responsive {
    width: 200px;
  }

  .jatitle {
    margin-top: 50px;
    width: auto;
  }

  .jasubtitle {
    width: auto;
  }

  .carousel-item > div > img {
    width: 150px;
  }
  .jacard-p {
    font-size: 15px !important;
  }
  .jacollabs-grid > .row {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .jasmallcard-img {
    width: 125px;
    height: 125px;
  }
  .jasmallcard {
    display: flex;
    flex-direction: column;
  }
  .jasmallcard > .col > a {
    font-size: 12px !important;
  }

  .jafooter {
    flex-direction: column;
    padding: 20px;
  }
  h3 {
    font-size: 15px !important;
    text-align: center;
  }
  .paws-image {
    width: 300px !important;
  }
  .javideos {
    display: flex !important;
    flex-direction: column !important;
  }

  .javideos > div {
    align-self: center !important;
  }
  .jafooter > div > div > a,
  .jafooter > div > div > span {
    font-size: 14px !important;
  }

  .jacopyright {
    font-size: 14px;
  }
}

@media (max-width: 1590px) {
}
