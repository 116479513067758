.janavbar {
  position: fixed;
  z-index: 3;
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  border: none !important;
  margin-left: 13px;
  box-shadow: 0 1px 8px rgb(0 0 0 / 10%);
}

.nav-item {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  font-family: JosefinSans-Light;
  max-width: fit-content;
}

.nav-item > a {
  width: max-content;
}

.janavbar-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.janavbar-phone {
  margin-right: 30px;
}
.janavbar-flags-socials {
  display: flex;
  gap: 30px;
}

.jasocials {
  display: flex;
  gap: 10px;
}
