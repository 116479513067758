.jaaboutbody-block {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #f3f3f3;
  padding: 20px;
  display: block;
  font-size: 18px;
}

.jaaboutbody-block > p {
  font-weight: 400;
  line-height: 1.5;
  color: #656565;
}

.jaabout-nav {
  display: flex;
  justify-content: center;
  gap: 10px;
}
