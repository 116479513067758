.carousel-indicators {
  bottom: -75px !important;
}

.carousel-control-prev {
  left: -75px !important;
}

.carousel-control-next {
  right: -75px !important;
}

a {
  text-decoration: none !important;
  font-size: 20px;
  color: #000000 !important;
}

.slick-card > .card {
  border: none !important;
}

.slick-card-body > p {
  font-family: JosefinSans-ExtraLight;
}

.jadonate-link {
  display: flex;
  justify-content: center;
  font-size: 15px;
}
